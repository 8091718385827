import { getAction, getByIdAction, postAction, putAction, getByTypeAction,deleteByIdAction } from  '@/api/common/request';
const baseURL = 'xun/common/xunTableCommon'


//查询
const getTableLists = (params) => getAction(baseURL + "/getTableList", params);

const getTableColumnsList = (params) => getAction(baseURL + "/getTableColumnsList", params);

const selectCodeGenerationList = (params) => getAction(baseURL + "/selectCodeGenerationList", params);

const saveCodeGeneration = (params) => postAction(baseURL + "/saveCodeGeneration", params);

const selectCodeGenerationById = (params) => getByIdAction(baseURL + "/selectCodeGenerationById", params);

const editCodeGeneration = (params) => putAction(baseURL + "/editCodeGeneration", params);

const downloadResWord = (params) => getByIdAction(baseURL + "/downloadResWord", params);



export {
    
    getTableLists,
    getTableColumnsList,
    selectCodeGenerationList,
    saveCodeGeneration,
    selectCodeGenerationById,
    editCodeGeneration,
    downloadResWord
}

