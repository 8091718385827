<template>
  <div>
    <el-dialog title="表数据" :before-close="handleClose" :visible.sync="dialog" size="40%">
      <span>
        <el-button @click="cancelForm()">取 消</el-button>
        <el-button type="primary" @click="confirmButton">确 定</el-button>
      </span>
      <el-form :model="queryParams">
        <el-input
          v-model="queryParams.tableName"
          placeholder="请输入表名称"
          @input="getTableListsButton"
        />
      </el-form>

      <el-table :data="tableList">
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="表名称" align="center" prop="tableName" />
        <el-table-column label="表的注释" align="center" prop="tableComment" />
        <el-table-column label="创建时间" align="center" prop="createTime" />
        <el-table-column label="更新时间" align="center" prop="updateTime" />
      </el-table>
     
    </el-dialog>
  </div>
</template>
<script>
import { getTableLists,saveCodeGeneration } from "@/api/system/tableGeneration/tables";
export default {
  data() {
    return {
      dialog: false,
      radio: null,
      tableNameChoose:null,
      queryParams: {
        pageSize: 10,
        current: 1,
        total: 1,
        tableName: ""
      },
      tableList: [],
      chooseData:{}
    };
  },
  methods: {
    show() {
      this.dialog = true;
      this.getTableListsButton();
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.dialog = false;
      //   this.$parent.menuListButton();
    },
    confirmButton() {
      console.log('s:',this.tableNameChoose)
      if(this.tableNameChoose==null)
        return this.$message.error("请选择一条数据");
        saveCodeGeneration(this.chooseData).then(res=>{
            console.log('data:',res)
            this.dialog = false;
             this.$parent.selectCodeGenerationListButton();
        })

    },
    getTableListsButton() {
      getTableLists(this.queryParams).then(res => {
        this.tableList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
     getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log('reos:',row)
      this.tableNameChoose = row.tableName;
      this.chooseData = row;
    },
  }
};
</script>
<style scoped>
</style>